export default {
  landing: {
    menu: {
      home: 'Home',
      about: 'About us',
      plataform: 'The platform',
      afiliate: 'Affiliates',
      marcas: 'Brands',
      team: 'Work team',
      history: 'Our history',
      products: 'Products',
      parnert: 'Partners',
      testimonios: 'Clients',
    },
    btn: {
      register: 'Sign up',
    },
    lbl: {
      login: 'Log in',
      changeLang: 'Change language',
      selectLang: 'Choose Language',
    },
    secOne: {
      title: 'Save your time and your efforts to manage destinations, trips and tourist services',
      subtitle:
        'Expand your business possibilities and provide multiple experiences to your customers, from the comfort of a single platform.',
    },
    secTwo: {
      title: 'Connect history, culture, people...',
      subtitle: 'Wamasol Plus is a business-to-business',
      subtitle1:
        'travel platform that promotes multidestination tourism packages around the world, with a special emphasis on the Caribbean and Central America. We connect history, traditions, nature, culture, heritage, and, above all, people.',
    },
    secThree: {
      title: 'Aimed at satisfying the tourism operation needs of companies in the travel industry around the world',
      proveedor: 'Providers',
      proveedor1:
        'Airlines, hotel chains, car rental companies, marinas, hostels, vacation rentals, tour guides, who offer their products with the aim of multiplying their sales channels.',
      agency: 'Travel Agencies and Tour Operators',
      agency1:
        'Tour operators that register with the aim of expanding the offer they provide to their customers through all their sales channels: web, mobile, social, face-to-face, telephone.',
    },
    secFour: {
      title: 'Our products',
      prod1: 'Flights',
      prod2: 'Car rental',
      prod3: 'Excursions',
      prod4: 'Yachts & Marinas',
      prod5: 'Diving & Fishing',
      prod6: 'Hotels',
      prod7: 'Transfers',
      prod8: 'Packages',
      prod9: 'Cruise ships',
      prod10: 'Health tourism',
    },
    secFive: {
      title: 'Our Partners',
      subtitle:
        'The platform is endorsed by prestigious brands in the tourism industry in the region, which guarantee the reliability of the services and the satisfaction of the end customer.',
    },
    secSix: {
      title: 'Structure and Brands',
      plus: 'B2B travel platform,',
      plus1: 'owned by Wamasol Unip Lda, offering registered companies the opportunity to expand their businesses:',
      plus2: 'Multiplying sales channels for suppliers, and',
      plus3: 'Expanding the product portfolio of affiliated travel agencies and tour operators.',
      tour: 'Wholesale travel agency,',
      tour1: 'founded in Guatemala, specializing in multidestination tourism.',
      tour2:
        'Connects clients from Europe, the United States, and Canada with the Caribbean and Central America region, focusing on offering destinations in Cuba and Guatemala.',
      tech: '',
      tech1: 'Technology Division',
      tech2:
        'of Wamasol Unip Lda, responsible for developing web and mobile applications for e-commerce, both proprietary and on-demand for third parties, primarily targeting the tourism industry and online stores.',
    },
    secSeven: {
      title: 'Meet our Team',
      favoriteFoot: 'Favorite food:',
      favoriteFilm: 'Favorite movie:',
      favoritePower: 'Favorite superpower:',
    },
    secEight: {
      title: 'Our clients',
      opi1:
        'Through our partnership with Wamasol we have found ways that work together when it comes to organizing activities on the ground and have been fortunate enough to cultivate stronger partnerships alone the way through the great services that they offer. We greatly count on Wamasol and are loooking forward to planning new events that bring people together in the near future.',
      opi1a: 'Diretor',
      opi1b: 'Senior Manager',
      opi1c: 'Cuba Oceans Program,',
      opi1d: 'Environmental Defense Fund',
      opi2:
        'We have found in Wamasol true partners, that not only help us solve all the challenges to organize activities on the ground, but deeply care about the work and the people involved. We are grateful to this partnership and will continue to work together in the future.',
      opi2a: 'Cuba Country Director',
      opi2b: 'Mesoamerica & Western Caribbean Program,',
      opi2c: 'Wildlife Conservation Society',
      opi3:
        'I have known the principals of Wamasol Tours, Fernando and Edgar, for years. They are extremely knowledgeable, diligent and friendly, and I know I can rely on them to organize first-rate travel for me or my friends to Cuba’s special places. If you are looking for a memorable tour and personable service, go with Wamasol.',
      opi3a: 'Cuba afficionado and Canadian conservation biologist',
    },
    secNine: {
      title: 'Our history',
      20181: 'Establishment of Wamasol Tours as a project.',
      20182: 'Signing of the first agreements for tourism operations with OnlineTours in Spain and in Cuba with the national receptive agency Cubatur, as well as the hotel chain Islazul.',
      20183: 'Commencement of tourism operations with clients from Spain, Canada, and Puerto Rico.',
      20201: 'Formation of the company Wamasol Tours SA in Guatemala.',
      20202: 'Creation of Wamasol Tech, the technology division of the company for the development of specialized web and mobile applications in tourism and e-commerce.',
      20211: 'Initiation of the internationalization of Wamasol Tech projects, signing the first development contract for Two Way Travel in the USA.',
      20221: 'Participation in the international tourism fair FITCUBA 2022 in Havana.',
      20222: 'Expansion of contracts for Wamasol Tours with tourist operators in Cuba: Gaviota Tours, Viajes Cubanacán, Amistur, and the Commercializer of Cuban Medical Services (SMC).',
      20223: 'Wamasol Tech expands its client portfolio with the signing of new development contracts with PROTURS (Mexico), Viajes Kronos (Spain), and TravelZun (USA).',
      20224: 'Start of negotiations with tour operators in Guatemala, Cuba and Spain for the integration of products in Wamasol Plus: TAG, INGUAT, MINTUR, IACC, IBERIA.',
      20231: 'Establishment of Wamasol Unip Lda in Portugal, inheriting the Technology and Wholesale Sales divisions of Wamasol S.A.',
      20232: 'Launch of Wamasol Plus, a B2B travel platform for the sale of multidestination tourist packages.',
      20233: "Wamasol is included in the roster of providers of the United Nations Agencies' system.",
      20241: 'Participation in the Lisbon Tourism Exchange,  as part of the Lisbon International Fair.',
      20242: 'Expansion of the portfolio of tourist products, integration with Hotetec, Dingus, Juniper, D-edge.',
      20243: 'Signing of a contract with the first Portuguese hotel chain, Vila Galé.',
    },
    secFooter: {
      body:
        'Wamasol Unipessoal Lda. is a company founded in Portugal in 2023, responsible for operating its own B2B travel platform, specializing in multidestination tourist packages.',
      service: 'Services',
      serv1: 'Flights',
      serv2: 'Hotels',
      serv3: 'Transfers',
      serv4: 'Car rental',
      serv5: 'Excursions',
      serv6: 'Cruise ships',
      serv7: 'Diving and Fishing',
      about: 'About us',
      about1: 'The platform',
      about2: 'Affiliates',
      about3: 'Brands',
      about4: 'Work team',
      about5: 'Our history',
      contact: 'Contact',
    },
    footer: {
      powerd: 'Powered by Wamasol Tech,',
      allRight: 'All rights reserved',
    },
  },
  plataforma: {
    title: 'General data of the platform',
  },
  login: {
    title: 'Administration panel',
    subTitle: 'Please sign-in to your account',
    forgot: 'Forgot Password?',
    remember: 'Remember Me',
    newAccount: 'New on our platform?',
    createAccount: 'Create an account',
  },
  register: {
    title: 'Adventure starts here',
    subtitle: 'Make your app management easy!',
    exist: 'Already have an account?',
    btnIn: 'Login',
    btnUp: 'Register',
    titleForm: 'Registration Form',
    titlePerson: 'General information',
    subTitlePerson: 'Configure your info. general',
    subTitleAgente: 'Sales agent details',
    subtitlePerson: 'Personal information',
    namePerson: 'Name and surname',
    emailPerson: 'Email',
    codePais: 'Country code',
    codidoPais: 'Country code',
    noTelefo: 'Phone number',
    subtitleCompany: 'Company information',
    nameCompany: 'Company name',
    siteWebCompany: 'Website',
    titleFactura: 'Billing information',
    configInfo: 'Configure the information',
    titleFiscal: 'Tax information',
    idFiscal: 'Tax identification number',
    titleFact: 'Billing Data',
    nameFact: 'Beneficiary Legal Name',
    cifNif: 'NIF/NIT',
    titleBanco: 'Bank information',
    dataAccount: 'Account data',
    noBanco: 'Bank account number',
    accountBank: 'Bank account',
    tipoBanco: 'Bank account type',
    tipoFiscal: 'Tax ID Type',
    moneda: 'Currency',
    nameBanco: 'Name of the bank',
    codeSWIFT: 'Swift code',
    codeSucursal: 'Branch Code',
    address: 'Bank address',
    codePostal: 'Postal Code',
    locality: 'City',
    country: 'Country',
    profile: 'Choose your profiles',
    typeAfiliado: 'Affiliate Type',
    typeAgente: 'Type of agent and authorized products',
    profileSub: 'Tell us a little about your Company',
    infoAgente: 'Agent Information',
    person: 'Person',
    idemComercial: 'Same as commercial',
    nameLegal: '  Legal name',
    nameComercial: 'Tradename',
    msgExistCompany: 'Sorry, you are already registered. Please contact your administrator.',
    msgExistEmailCompany: 'Sorry, the email {email} is already registered. Please contact your administrator.',
    titleSolicitude: 'Request made from',
  },
  forgot: {
    title: 'Forgot Password?',
    subtitle: "Enter your email and we'll send you instructions to reset your password",
    btn: 'Send reset link',
    btnBack: 'Back to login',
    errorUserInex: 'We are sorry but we have not found {email} in our system.',
    errorUserInac: 'Sorry but {email} is not active in our system.',
    sendEmail: 'Notification has been sent to {email}. Follow the steps to recover your account',
  },
  reset: {
    title: 'Reset Password',
    subtitle: 'your new password must be different from previously used passwords',
    btn: 'Set New Password',
    btnBack: 'Back to login',
    msgSuccess: 'Password reset. Please login',
  },
  activeAcount: {
    title: 'Activate your account',
    subTitle: 'with these credentials you can manage your company',
    password: 'New Passwor',
    confirmPassword: 'Confirm Password',
    btn: 'Activate',
    msgInvalidToken: 'Your token is invalid',
    msgCaduquedToken:
      'The link you followed has expired. You are visiting a page that has been generated to activate a specific service on {item}. If you think it is a mistake, we ask that you contact us immediately.',
    msgSuccess: 'User activated. Please login',
  },
  lbl: {
    ocupations: 'Ocupations',
    addOcupation: 'Add Ocupation',
    deleteOcupation: 'Delete Ocupation',
    month: 'Month',
    price: 'Price',
    prices: 'Prices',
    changePrice: 'Change Price',
    changeSeat: 'Change No. Seats',
    vigenteAl: 'effective from {from} to {to}',
    file: 'File',
    frecuenciasContrate: 'Contract Frequencies',
    email: 'Email',
    emails: 'Emails',
    password: 'Password',
    old_password: 'Current Password',
    new_password: 'New Password',
    confirmation_password: 'Confirm Password',
    min_length_password: "Make sure it's at least 6 characters.",
    user: 'User',
    name: 'Name',
    oferta: 'Ofert',
    monto: 'Mount',
    daysBefore: 'Previous days',
    update: 'Updated',
    publicada: 'Publiced',
    cancelada: 'Canceled',
    suplementReducc: 'Supplements and reductions',
    priceByNight: 'Price per night',
    priceByPax: 'Price per pax',
    priceByRoom: 'Price per room',
    priceByPaxDoble: 'Price per pax in double occupancy',
    priceByRoomDoble: 'Price per room in double occupancy',
    priceByPaxSimple: 'Price per pax in single occupancy',
    priceByRoomSimple: 'Price per room in single occupancy',
    priceByPaxTriple: 'Price per pax in triple occupancy',
    priceByRoomTriple: 'Price per room in triple occupancy',
    priceFirstChild: 'Price for the first child ({years} years)',
    priceSecondChild: 'Price for the second child ({years} years)',
    priceInfant: 'Price for infants (less than {year} years)',
    beneficiosAdd: 'Additional benefits',
    doble: 'Double',
    simple: 'Simple',
    triple: 'Triple',
    firstChild: '1st child',
    secondChild: '2nd. child',
    infant: 'Infants',
    confirmDateUpdate: 'Confirmar la fecha de actualización',
    canceleDateUpdate: 'Cancelar la fecha de actualización',
    nameOlds: 'Previous names',
    nameReference: 'The Reference will be exclusive for the Managers and will never be shown to the final clients.',
    nameSecond: 'Second Name (if it appears on the document)',
    apellidos: 'Surnames',
    birthday: 'Date of Birth',
    changeFoto: 'Change photo',
    infoFoto: 'Allowed JPG, GIF or PNG',
    nationality: 'Nationality',
    noDocument: 'Document number',
    sexMasc: 'Male',
    sexFem: 'Female',
    sexOther: 'Other',
    sexo: 'Gender',
    leng: 'Language',
    dateCaduque: 'Date of Expiry',
    cardFidelidad: 'Fidelity card',
    noCardFidelidad: 'Number',
    addCardFidelidad: 'Add fidelity card',
    hideCardFidelidad: 'Hide fidelity card',
    contactPasajero: 'Passenger contacts',
    codeOFAC: 'OFAC code',
    reserveConfirm: 'Confirmed reservation',
    reserva: 'Booking',
    emition: 'Emission',
    claveConfirm: 'Confirmation code',
    noConfirm: 'Nº confirmation',
    operaLocal: 'Local Operator',
    atension: 'ATTENTION',
    atension1:
      'This voucher is invalid if you do not have your confirmation key, which must match the one sent by the provider.',
    atension2: 'The passenger is responsible for the validity of their passport or other required documentation.',
    atension3: 'Subject to government approval.',
    ticketNumber: 'Ticket Number',
    locator: 'Locator',
    pnr: 'PNR',
    contactEmergency: 'Emergency contact',
    estableceContactEmergency: 'Set this emergency contact to all passengers',
    nameSurname: 'Name and surname',
    finallyReserve: 'Finish Reservation',
    finallyReserveText1:
      'You can issue tickets in the Reservations section. The reservation will be automatically canceled at the end of this period.',
    finallyReserveText2:
      'The airline has the right to cancel the reservation until the time limit of the ticket expires.',
    note: 'Note',
    finallyReserveText3: 'By clicking Book, you agree with',
    finallyReserveText4:
      "and you confirm that you received the customer's consent to share their personal data and process it to book an air ticket.",
    billetesFligh: 'Air tickets',
    rentaCar: 'Car rental',
    rentaCarByDay: 'Car rental x day',
    total: 'Total',
    inTotal: 'en total',
    night: 'night',
    totalDesde: 'Total from',
    byDayDesde: 'Per day from',
    priceTotal: 'Total price',
    priceByCliente: 'Public sale price',
    otherCarge: 'Other positions and supplements',
    payOnline: 'To pay online',
    payRent: 'To pay in the rentator',
    payAloja: 'To pay in the hotel',
    incluyeComition: 'Includes commission',
    incluyeMargenCom: 'Includes trade margin',
    incluyeDescuento: 'Includes discount',
    cargoSuplement: 'Charges and Supplements',
    totalPay: 'Total to pay',
    totalPayOnline: 'Total to pay online',
    totalPayRent: 'Total to pay at the rental company',
    descuento: 'Discount',
    addDescuento: 'Add Discount',
    precioInitial: 'Starting price',
    conMarkups: 'With markup',
    conDescuento: 'With discount',
    changePassword: 'Change Password',
    identificador: 'Identifier',
    color: 'Color',
    code: 'Code',
    slug: 'Slug',
    active: 'Active',
    canceled: 'Cancelad',
    vencido: 'Vencid',
    inactive: 'Inactive',
    showFront: 'Registry',
    description: 'Description',
    itemsPerPage: 'Per page',
    actions: 'Actions',
    rol: 'Role',
    permisoEdit: 'Update permission',
    perfil: 'Profile',
    typeProduct: 'Product Type',
    products: 'Products',
    pcFee: 'Percentage or Fee',
    pc: 'Percentage',
    fee: 'Value',
    infoAdd: 'Additional Information',
    info: 'Information',
    ventaDirectas: 'Direct sells',
    valor: 'Value',
    pc1: '%',
    all: 'All',
    company: 'Company',
    contacto: 'Contact',
    profiles: 'Profiles',
    dateSoliciti: 'Application date',
    manageSolic: 'Manage Approval',
    acceptSolic: 'Approve Request',
    denegSolic: 'Deny Request',
    address: 'Address',
    telefonos: 'Phones',
    telefono: 'Phone',
    horarios: 'Schedules',
    horariosDe: 'Hours of',
    verifyHorarios: 'Check the schedules',
    horario: 'Hours',
    from: 'From',
    apertura: 'Opening',
    cierre: 'Closing',
    to: 'To',
    ida: 'Departure',
    ida_vuelta: 'Departure and return',
    nultiple_destino: 'Multiple destinations',
    back: 'Return',
    longitud: 'Longitude',
    latitud: 'Latitude',
    showMap: 'Show Map',
    hideMap: 'Hide Map',
    airport: 'Airport',
    city: 'City',
    state: 'State',
    states: 'States',
    zip: 'Zip',
    country: 'Country',
    countries: 'Countries',
    provinceEstado: 'Province / State',
    locality: 'Location',
    localities: 'Locations',
    typeAfiliado: 'Affiliate Type',
    createAdmin: 'Create Admin',
    dataAdmin: 'Administrator Data',
    promover: 'To promote',
    dateVuelo: 'Flight date',
    dateArrival: 'Arrival date and time',
    ruta: 'Route',
    numeroVuelo: 'Flight number',
    operador: 'Operated by',
    vuelo: 'Flight',
    de: 'of',
    a: 'to',
    dia: 'day',
    day: 'days',
    mas: 'more of',
    vuelos: 'flight',
    cars: 'cars',
    contratos: 'contracts',
    listContratos: 'List of contracts',
    selectVuelo: 'Select flight',
    contrateInsert: 'You must first specify the operator or airline in order to select the flight number.',
    aeronave: 'Aircraft',

    // aerolinea: 'Airlines',
    origen: 'Origin',
    terminal: 'Terminal',
    puerta: 'Door',
    destino: 'Destination',
    iata: 'IATA',
    checkIn: 'Check In',
    llegada: 'Arrival',
    hour_departure: 'Departure',
    departure: 'Departure',
    hour_arrival: 'Arrival',
    arrival: 'Arrival',
    durationVuelo: 'Flight Time',
    durationEscala: 'Duration Scale',
    durationEscalaRango: 'until {time} hours',
    durationViaje: 'Travel time',
    escala: 'scale',
    escalaIn: 'Scale in',
    escalad: 'scales',
    escalas: 'Scales',
    escala1: 'All flights',
    escala2: 'Direct',
    escala3: 'Up to 1 scale',
    duration: 'Duration',
    aLa: 'at',
    origenSearch: 'What is the origin?',
    noResultSearchFlight: 'We did not find results for your search',
    hacia: 'To',
    destinoSearch: 'Where?',
    citySearch: 'Select the city',
    charter: 'Charter',
    flight: 'Flight',
    selectFlight: 'Select the flight',
    clases: 'Classes',
    clase: 'Class',
    cupo: 'Quotas',
    editCupos: 'Edit quotas and prices to {date}',
    cupoDisp: 'Space available',
    price_adult: 'Adult Price',
    price_min: 'Price',
    releaseEstancia: 'Release and Minimum Stay',
    releasePeriod: 'Release Period',
    estanciaMin: 'Minimum Stay',
    estanciaMax: 'Maximum Stay',
    priceEstancia: 'Stay Prices',
    acomodation: 'Accommodation',
    ocupationMax: 'Maximum occupancy',
    ofertSpecial: 'Special offers',
    saleAnticip: 'Advance purchase (EBB)',
    minPax: 'Min paxs',
    maxAdl: 'Max adl',
    maxMnr: 'Max child',
    maxPax: 'Max paxs',
    adl: 'Adl',
    adulto: 'Adult',
    adultos: 'Adults',
    childs: 'Childs',
    child: 'Child',
    childsFlight: 'from 2 to 12',
    infants: 'Infants',
    infantsFlight: 'less than 2',
    mnr: 'Chl',
    menor: 'Child',
    menors: 'Childs',
    young: 'Youths',
    infat: 'Infant',
    rangeAge: 'Age range',
    mayorQue: 'Greater than',
    hasta: 'until',
    price_child: 'Price Children',
    price_infant: 'Infant Price',
    infant_free: 'Free Infant',
    is_cabina: 'Cabin',
    is_bodega: 'Luggage',
    is_personal: 'Personnel',
    local: 'Local',
    equipaje_personal: {
      title: 'Personal item',
      dimensiones: 'Dimensions',
      peso: 'Weight',
      precio: 'Price',
      para: 'Applies to',
      adul: 'Adult',
      child: 'Child',
      inf: 'Infant',
    },
    equipaje_cabina: {
      title: 'Hand baggage',
      dimensiones: 'Dimensions',
      peso: 'Weight',
      precio: 'Price',
      para: 'Applies to',
      adul: 'Adult',
      child: 'Child',
      inf: 'Infant',
      eq: 'Baggage',
      eqB: 'Hand',
    },
    equipaje_bodega: {
      title: 'Luggage in hold',
      dimensiones: 'Dimensions',
      addMaleta: 'Add Suitcase',
      peso: 'Weight',
      precioMaleta: 'Suitcase',
      max_exceso_equipaje: 'Maximum weight',
      exceso_equipaje: 'Excess baggage',
      addPeso: 'Add Weight',
      tasa_despacho: 'Dispatch rate',
      para: 'Applies to',
      adul: 'Adult',
      child: 'Child',
      inf: 'Infant',
      eq: 'Luggage in',
      eqB: 'Hold',
    },
    maleta_adicional: {
      title: 'Additional suitcase',
      dimensiones: 'Dimensions',
      peso: 'Weight',
      cant: 'Cant.',
      para: 'Applies to',
      adul: 'Adul.',
      child: 'Chil.',
      inf: 'Inf.',
    },
    precio_adicional: {
      title: 'Additional price',
      um: 'Measure unit',
      de: 'from',
      a: 'to',
      precio: 'Price',
      para: 'Applies to',
      adul: 'Adul.',
      child: 'Chil.',
      inf: 'Inf.',
    },
    comidas_bordo: {
      title: 'Meals on board',
      para: 'Applies to',
      adul: 'Adul.',
      child: 'Chil.',
      inf: 'Inf.',
    },
    reembolso: {
      title: 'Refunds',
      para: 'Applies to',
      adul: 'Adul.',
      child: 'Chil.',
      inf: 'Inf.',
    },
    cambio_fecha: {
      title: 'Date changes',
      para: 'Applies to',
      adul: 'Adul.',
      child: 'Chil.',
      inf: 'Inf.',
    },
    elec_asiento: {
      title: 'Choice of seats',
      para: 'Applies to',
      adul: 'Adul.',
      child: 'Chil.',
      inf: 'Inf.',
    },
    frecuency: 'Frequency',
    frecuencias: 'Frequencies',
    frecuenciasFlight: 'Flight frequencies',
    temporadas: 'Seasons',
    fromSeason: 'start of season',
    toSeason: 'End of season',
    validez: 'Validity',
    vigencia: 'Validity',
    fromValidez: 'Valid from',
    toValidez: 'Valid until',
    dimension: 'Dimension',
    largo: 'Long',
    alto: 'High',
    ancho: 'Wide',
    tipo: 'Type',
    um: 'UM',
    lineal: 'Lineal',
    reference: 'Reference',
    peso: 'Weight',
    pesoMax: 'Max.',
    fechasVuelo: 'Flight dates',
    todosDay: 'All',
    todosDays: 'Every day',
    semanaDay: 'Weekdays',
    especifDay: 'Specific days',
    detailContrate: 'Contract Conditions',
    infoContrate: 'Contract Information',
    tarif: 'Rate',
    tarifa: 'Rates',
    rangeDaysRent: 'Rental Days Range',
    conditionContrate: 'Tariff Policy',
    rulesTarifa: 'Fare Rules',
    otherTarifa: 'Other fees',
    date: 'Date',
    dateCreate: 'Creation date',
    dateRegistro: 'Registration date',
    car: 'Car',
    cantPuertas: 'Number of doors',
    cantPaxs: 'Number of seats',
    cantMaletas: 'Bags',
    small: 'Small',
    big: 'Big',
    typeCar: 'Car type',
    travels: 'Travellers',
    doors: 'Doors',
    umComb: 'UM',
    capacityComb: 'Fuel capacity',
    consumoComb: 'Fuel consumption',
    motor: 'Engine',
    only_adult: 'Adults Only',
    publico: 'Published',
    stopSale: 'Stop selling',
    sinPublicar: 'Unpublished',
    modality: 'Modality',
    sugerido: 'Suggested',
    orderSugerido: 'Priority',
    infoSugerido: 'Define the order to display the product in various sections, availability pages and searches.',
    sinSugerir: 'Without suggest',
    searchImages: 'Search the images',
    searchVideos: 'Search the videos',
    addImages: 'Add images',
    galery: 'Gallery',
    details: 'Details',
    edadConductor: "Driver's age",
    proveedor: 'Provider',
    acredor: 'Creditor',
    deudor: 'Debtor',
    cadena: 'Cadena',
    aerolinea: 'Airline',
    validFrom: 'Valid from',
    validTo: 'Valid until',
    change: 'Changes',
    dataOld: 'Old data',
    propuesta: 'Change Proposal',
    oficina: 'Office',
    time_completo: 'Open 24 hours',
    bag: 'Luggage',
    bagNot: 'Not available',
    bagNoInclude: 'Baggage not included in the price',
    pieza: '{n} piece | {n} pieces',
    esca: '{n} scale | {n} scales',
    bagHand: 'Hand luggage',
    filtros: 'Filters',
    hotelsFilters: 'hotels of',
    clearFilters: 'Clear filters',
    nameAlojamientoFilters: 'Accommodation name',
    categoryFilters: 'Category',
    priceFilters: 'Price',
    typeDestinyFilters: 'Destination type',
    cadenaFilters: 'Hotel chain',
    marcaFilters: 'Hotel brand',
    typeReserveFilters: 'Reservation type',
    contrateFilters: 'Contrates',
    servicesFilters: 'Services',
    interesesFilters: 'Interests',
    recomendadosFilters: 'Recommended',
    orderByFilters: 'Order by',
    showMoreFilters: 'See more',
    showLessFilters: 'See less',
    byMejorOpcion: 'Better option',
    byMasEconomico: 'More economical',
    byMasRapido: 'Faster',
    searchDestiny: 'Searching destinations',
    prepararSelect: 'Prepare selection',
    borrarSelect: 'Clear list of preferred flights',
    backSearch: 'Back to search results',
    tarifaComplete: 'Full route fee',
    pax: 'Passenger',
    systemCode: 'Data to generate your reservations',
    systemCodeProduct: 'Can be one letter or one number',
    siglas: 'Acronym',
    programationFlights: 'Flight schedule',
    client: 'Client',
    product: 'Product',
    compra: 'Purchase',
    service: 'Service',
    venta: 'Sale',
    afiliado: 'Affiliate',
    proximas: 'Upcoming',
    actives: 'Active',
    sendEmail: 'Send by email',
    or: 'or',
    in: 'in',
    downloadPdf: 'Download in PDF',
    hourario: 'Schedule',
    calculo: 'Calculation',
    settingGeneral: 'General configuration',
    settingBot: 'Bot',
    settingUser: 'User',
    titleChat: 'Chat title',
    colorTheme: 'Chat theme color',
    bgMessage: 'Message background',
    colorMessage: 'Text color',
    avatarSize: 'Avatar size',
    inputPlaceholder: 'Input placeholder',
    order: 'Order',
    message: 'Menssage',
    options: 'Opctions',
    optionTitle: 'Title Option',
    selectTalking: 'Start Conversation',
    talkingArchive: 'Archived Conversations',
    companyConfiguration: 'Company',
    isCompany: 'Is a Company',
    dataCompany: 'Company data',
    viewReserve: 'See reservations',
    nameViajero: "Client's name",
    codeReserva: 'Reservation code',
    codeCotization: 'Cotization code',
    stateReserva: 'Reservation Status',
    stateAccount: 'Account status',
    stateCotization: 'Cotization Status',
    afiliate: 'Affiliate',
    afiliateAgency: 'Affiliated agency',
    vendedor: 'Seller',
    nameAPI: 'Identifier for your Tokens',
    account: 'Account',
    security: 'Security',
    edad: 'Age',
    menorDe: 'Less than',
    mayorDe: 'Older than',
    noReembolsable: 'Non refundable',
    permiteCambios: 'Allow changes',
    cambiosView: 'See more details about changes and cancellations in the next step.',
    equipaje: 'Luggage',
    contacts: 'Contacts',
    contact: 'Contact',
    incluida: 'Incluide',
    permitida: 'Permited',
    noPermitida: 'Not allowed',
    sobrepeso: 'Overweight',
    sobreDimension: 'Oversize',
    dimensionMax: 'Maximum Dimension',
    action: 'Action',
    rental: 'Renter',
    comision: 'Commissionable contract',
    typeContrato: 'Contract type',
    contrato: 'Contract',
    typeReserve: 'Reservation Type',
    modelPrice: 'Price model',
    firstDay: 'First day',
    dayByDay: 'Day x day',
    modalidad: 'Modality',
    suplemento: 'Supplement',
    reduccion: 'Reduction',
    periodoValidez: 'Period of validity',
    cat: 'Category',
    others: 'Others',
    other: 'Other',
    brandModel: 'Brand and Model',
    seguro: 'Secure',
    deposito: 'Deposit',
    depositoGarantia: 'Down Payment',
    tarifaRetorno: 'Return fee',
    garantia: 'Warranty',
    dayExtra: 'Bonus day',
    onRequest: 'On Request',
    freeSale: 'Free Sale',
    recogida: 'Pickup',
    dateRecogida: 'Pick up date',
    entrega: 'Delivery',
    dateEntrega: 'Deliver date',
    recogidaEntrega: 'Pickup and Delivery',
    entregaDiffRecogida: 'Deliver to a different location',
    gestionarOficinas: 'Manage rental offices',
    addOficinas: 'Add rental offices',
    filterOficinas: 'Filter rental offices',
    checkAll: 'All',
    addOficineRentadora: 'Add offices to the rental company',
    rentaAplicaCuba: 'Every day of rent with the price of the first day',
    detallesReserva: 'Details of the reserve',
    conductorPrincipal: 'Main driver',
    conductorAditional: 'Aditional driver',
    byNight: 'By night',
    byRoom: 'By room',
    byRoomNight: 'By room/night',
    byPax: 'By pax',
    byPaxNight: 'By pax/night',
    byDay: 'By day',
    byKm: 'By km',
    bySerice: 'By service',
    byUnidad: 'By unit',
    byPeso: 'By weight',
    optional: 'Optional',
    obligatorio: 'Mandatory',
    infoImportant: 'Important information',
    termsConditions: 'Terms and Conditions',
    policesCancele: 'Cancellation and no-show policies',
    docConductor: 'Documentation required for the driver',
    policesComb: 'Fuel policies',
    intoProvince: 'Within the province',
    pto: 'Point',
    passport: 'Passport number',
    docIdentidad: 'Identity document',
    daysRent: 'Days of rent',
    orSimilar: 'or similary',
    readTermsConditions: 'I have read and accept all the policies, terms and conditions detailed below',
    observations: 'Observations of the Contract (Not visible to the client)',
    observation: 'Observation',
    hotels: 'Hotels',
    hotelsFilter: 'Filtered Hotels',
    hotelsAsociado: 'Associated Hotels',
    asociarHotels: 'Associate Hotels ',
    addHotels: 'Add Hotels ',
    gestionCodes: 'Manage Codes for {item}',
    gestionCode: 'Manage Codes',
    noHotelsAsociados: 'There are no hotels to associate',
    hotelsAsociados: 'There are no associated hotels',
    asociateRoom: 'Associate room',
    room: 'Room',
    rooms: 'Rooms',
    regimen: 'Regimen',
    and: 'and',
    plan: 'Plan',
    uso: 'Use',
    noSeasonHotel: 'There are no seasons for the hotel {item}',
    required: 'Required',
    onlyNumber: 'Only number',
    cantBars: 'Cant. pubs',
    cantRoom: 'Cant. rooms',
    cantRest: 'Cant. restaurants',
    cantCafeterias: 'Cant. coffee',
    cantSalon: 'Cant. salon',
    cantPiscinas: 'Cant. pools',
    hotelDestinos: 'City, region or name of accommodation',
    dateIn: 'Entry date',
    fromAl: 'from the',
    dateOut: 'Departure date',
    dateSale: 'Sale date',
    toAl: 'until',
    ocupation: 'Occupation',
    roomD: 'room',
    edadChild: 'Age of the youths on the date of travel',
    cantChild: '{n} youth | {n} youths',
    years: 'years',
    age: 'age',
    modeloPrice: 'Prices model',
    paxNight: 'pax/night',
    roomNight: 'room/night',
    supl: 'Supl.',
    reduc: 'Reduc.',
    nightsFrom: '{n} night from | {n} nights from',
    moreLeer: 'Read more',
    moreSee: 'See more',
    lessLeer: 'Read less',
    lessSee: 'See less',
    infoHuesp: 'Guest Information',
    huesp: 'Guest',
    huespPrincipal: 'Guest main',
    addDataHuesp: 'Add data from other guests',
    deleteDataHuesp: 'Remove the data of the other guests',
    roomingList: 'Rooming list',
    dateReservation: 'Reservation of Date',
    reserveFrom: 'Book from',
    reserveHome: 'Start of reservation',
    reserveTo: 'Reserve until',
    reserveEnd: 'End of reservation',
    dateCotization: 'Cozitation of Date',
    cotizationFrom: 'Quote from',
    cotizationTo: 'Quote until',
    dateHome: 'Start date',
    dateService: 'Service of date',
    serviceFrom: 'Service from',
    serviceTo: 'Service until',
    homeService: 'Service home',
    endService: 'Service end',
    cargaLote: 'Batch upload',
    existHotel: 'There is already a hotel with this name',
    updateHotel: 'This hotel will be updated with the new changes, since it already exists in the database.',
    inexistOperador: 'This operator does not exist',
    problemsHotels: 'Please check what you want to do with these {items}.',
    problemsOperador: 'Problems with uploaded operators. Please check them.',
    inexistMarcaAsociada: 'This associated brand does not exist',
    problemsMarcaAsociada: 'Problems with uploaded associated brands. Please check them.',
    inexistCategoria: 'This category does not exist',
    problemsCategoria: 'Problems with uploaded categories. Please check them.',
    inexistAge: 'This age does not exist',
    problemsAge: 'Problems with raised ages. Please check them.',
    inexistTipoDestino: 'This type of destination does not exist',
    problemsTipoDestino: 'Problems with uploaded destination types. Please check them.',
    inexistServicio: 'This service does not exist',
    problemsServicio: 'Problems with uploaded services. Please check them.',
    problemsIntereses: 'Problems with interest rates. Please check them.',
    problemsNameHotel: 'Problems with uploaded hotel names. Please check them.',
    problemsHabitaciones: 'Problems with the rooms uploaded. please check them.',
    problemsPlanAlim: 'Problems with raised diets. Please check them.',
    problemsSuplement: 'Problems with uploaded supplements. Please check them.',
    problemsSuplementReduc: 'Problems with uploaded supplements/reductions. Please check them.',
    problemsReducc: 'Problems with raised reductions. please check them.',
    problemsPtoRecogida: 'Problems with points. please check them.',
    gestionChange: 'Manage changes',
    services: 'Services',
    orderFileColumn: 'File column order',
    descriptionCampos: 'Description of the fields',
    notModel: 'There is no model for that provider. Contact support, please',
    hotel: 'Hotel',
    notPublic: 'unpublished',
    infoPendiente: 'with Pending info',
    sinOperador: 'sin Operador',
    sinMarcaAsociada: 'without Associated Brand',
    sinFotos: 'without photos',
    sinTagFotos: 'without Photo Tag',
    sinTagPrincipal: 'without Main Tag',
    sinDireccion: 'without Direction',
    sinLocalidas: 'without location',
    sinCrop: 'without resize',
    sinMarca: 'without Brand',
    sinModelo: 'without Model',
    sinTransmision: 'without Transmition',
    sinCarroceria: 'without Carroceria',
    sinCombustible: 'without fuel',
    sinCantPuertas: 'without count doors',
    sinCantPaxs: 'without count paxs',
    sinCantBagBig: 'without count Big Bag',
    sinCantBagSmall: 'without count Small Bag',
    sinUm: 'without unit of measure',
    sinCapacidad: 'without fuel capacity',
    sinConsumoComb: 'without fuel consume',
    sinMotor: 'without Motor',
    sinCaracteristicas: 'without features',
    sinPrioridad: 'without priority',
    sinRooms: 'without rooms',
    sinOcupation: 'without ocupation',
    sinPlan: 'without regime',
    sinPrice: 'without prices',
    sinContact: 'without contacts',
    vencimiento: 'expiration',
    vencimiento3: 'in 3 months',
    vencimiento6: 'in 6 months',
    principal: 'Main',
    problemsMarcas: 'Problems with uploaded brands. Please check them.',
    problemsModelos: 'Problems with uploaded models. Please check them.',
    problemsTransmisiones: 'Problems with uploaded transmitions. Please check them.',
    problemsTipoAutos: 'Problems with uploaded bodies. please check them.',
    problemsCombustible: 'Problems with raised fuels. Please check them.',
    problemsCaracteristicas: 'Problems with uploaded features. please check them.',
    problemsTags: 'Problems with uploaded tags. Please check them.',
    cantImgUpload: 'They will only go up to {item} images at once.',
    sizeVideoUpload: 'They will only go up to {item} videos at once.',
    execImgUpload: 'Exceeded the {item} images allowed.',
    execFilesUpload: 'Exceeded the {item} files allowed.',
    cantFilesUpload: 'Allowed {item} files.',
    porciento: 'Percent',
    travel: 'Travel',
    reserve: 'Booking',
    pay: 'Payment',
    descuent: 'Discount',
    calculateBy: 'Calculated by',
    before: 'Before',
    estancia: 'Stay',
    titleTipoContrate:
      'You must specify if the Contract applies by Category (several similar vehicles) or by Make and Model (it is allowed to rent a specific vehicle).',
    titleComitionContrate: 'The public price is maintained and the commission granted by the provider is applied.',
    titleModeloPriceContrate: 'Applies to the calculation of rates and changes in seasons.',
    allInclude: 'All include',
    markupsAfiliate: 'Markups for affiliate sales',
    markupsOwner: 'Markups for own sales',
    saleFisic: 'Physical sales',
    fisic: 'Physical',
    saleOnline: 'Online sales',
    modelPvp: 'PVP model',
    comitionsOwner: 'Commissions for own sales',
    comitionsAfiliate: 'Commissions for affiliate sales',
    numerConfirmed: 'Confirmation number',
    commentsGestor: 'Comments for the manager',
    commentsClient: 'Customer feedback',
    comment: 'Comment',
    timeGrace: 'Grace time',
    createReserve: 'A new reservation has been created, with code',
    createCotization1: 'A new quote has been created, with code',
    createCotization2: 'Type of service',
    createCotization3: 'Date of service',
    createCotization4: 'User',
    incluyeDayGrace: 'includes +1 day',
    incluyeDayGraceMsg:
      'An extra day of rent is added to the contracted service, due to the delivery time outside the grace period provided in the contract, with respect to the car pick-up time.',
    cantRecipients: 'Total recipients',
    recipients: 'Recipients',
    programeSend: 'Schedule shipment',
    zoneHour: 'Time zone',
    dateTime: 'Date and Time',
    content: 'Content',
    template: 'Template',
    emailsReserve: 'Reservation emails',
    infoEmailsReserve: 'Email accounts for automatic sending of reservation confirmations, vouchers, invoices, etc.',
    sendNotification: 'Send notification',
    incluye: 'Incluye',
    clients: 'Clients',
    title: 'Title',
    urlExtern: 'External url',
    uploadVideo: 'Video upload',
    changeVideo: 'Video change',
    dates: 'Dates',
    typeOffert: 'Offert type',
    offertSpetial: 'Offert spetial',
    offline: 'Offline',
    offlineMsg:
      'If you deactivate the Affiliate, you would be disconnecting from the System and you will not be able to check availability, generate sales, etc.',
    userOffline: 'This content is not available for you at this time.',
    notChangesUpdate: 'You have unsaved changes.  Are you sure you want to continue?',
    payMethod: 'Payment method',
    wayMethod: 'Way to pay',
    waysMethod: 'Ways to pay',
    credit: 'Credit',
    cardName: 'Name on the card',
    cardNumber: 'Card number',
    cardExpire: 'Expiration',
    transfer: 'Transference',
    transferBank: 'Bank transference',
    linkPay: 'Payment link',
    cardCredit: 'Credit card',
    payCard: 'Pay card',
    checkBank: 'Bank check',
    cash: 'Cash',
    cobradoPor: 'Charged for',
    caducada: 'Expired',
    vigente: 'Current',
    time: 'Time',
    balances: 'Balance',
    balance: 'Balance',
    limitCredit: 'Credit limit',
    creditRest: 'Available credit',
    deuda: 'Debt',
    limitTime: 'Time limit',
    timeAvailable: 'Available time',
    fondoRest: 'Positive balance',
    deposit: 'Deposits',
    credits: 'Credits',
    operaCont: 'Accounting operations',
    compPay: 'Payment vouchers',
    dateOperation: 'Operation date',
    dateCreation: 'Creation date',
    createBy: 'Created by',
    cantidad: 'Amount',
    typeOperation: 'Operation type',
    typeDeposit: 'Deposit type',
    totalDeposit: 'Total deposit',
    dateDeposit: 'Deposit date',
    depositBy: 'Deposited by',
    excecuteBy: 'Realized by',
    use: 'Use',
    observaciones: 'Observations',
    addBy: 'Added by',
    dateAddSistem: 'Date added to the system',
    incrementCredict: 'Does it increase current credit limit?',
    introducBySistem: 'Entered into the system by',
    dateIntroducBySistem: 'Date of introduction in the system',
    countAvailableBefore: 'Quantity available before the operation',
    countAvailableAfter: 'Amount available after the operation',
    dowloadExample: 'Sample CSV',
    fileDowloadExample: 'Sample {ext}',
    titlePrintCar: 'Select the quote you want to print',
    titleSendCar: 'Select the quote you want to send',
    printCar1: 'Current product only',
    printCar2: 'Include the products that are in the shopping cart',
    para: 'To',
    subject: 'Subject',
    adjuntCotization: 'Quotation attached',
    sendCotization: 'Quotation send',
    printCotization: 'Quotation print',
    downloadCotization: 'Quotation download',
    paquet: 'Package',
    agency: 'Agency',
    pagado: 'Paid',
    pendiente: 'Earring',
    transactions: 'Transactions',
    documents: 'Documents',
    history: 'History',
    localizador: 'Localizator',
    applyTo: 'Apply to',
    importe: 'Amount',
    carShop: 'Shopping cart',
    payDestino: 'Payable at destination',
    payOther: 'Other charges to pay',
    paymentOther: 'Other charges',
    toAeroline: 'to the airline',
    toRent: 'to the renter',
    toAlojamient: 'to the accommodation',
    ctaXCobrar: 'CTA x cobrar',
    cobrado: 'Cobrado',
    ctaXPagar: 'CTA x pagar',
    drivers: 'Drivers',
    document: 'Document',
    payToRent: 'to pay in rent',
    rentToCars: 'Car rental',
    rentToHotels: 'Alojamiento',
    withSecureInclude: 'w/ Insurance included',
    principate: 'major',
    adicional: 'aditional',
    suplente: 'alternate',
    andSuplente: 'and alternate',
    ilimit: 'ilimited',
    backCarShop: 'Return to shopping cart',
    backReserve: 'Return to reservation',
    cotization: 'Cotization',
    sameConductor: 'Same driver for all services',
    infoPendient: 'Pending information',
    completeInfo: 'Complete InformaTion',
    hidden: 'Hidden',
    show: 'Show',
    airplaneStruct: 'Seats aircraft',
    payEvidency: 'Proof of payment',
    evidency: 'Proof',
    formatPay: 'Accepted formats: {formats}',
    saldo: 'Balance',
    map: 'Map',
    ajusteRequered: 'Adjustment required',
    reembolsoRequered: 'Refund required',
    reembolsoTotal: 'Total',
    emitidoBy: 'Issued by',
    emitidoFrom: 'Issued from',
    emitidoTo: 'Issued to',
    dateEmitido: 'Date of issue',
    provideService: 'Service provider',
    importePay: 'Amount paid',
    verify: 'Check',
    recibidoBy: 'Received by',
    recibidoFrom: 'Received from',
    recibidoTo: 'Received to',
    dateRecibido: 'Reception date',
    deudorAfiliate: 'Agency, agent or client',
    statusPay: 'Payment status',
    cobradoBy: 'Charged by',
    cobradoFrom: 'Charged from',
    cobradoTo: 'Charged to',
    dateCobrado: 'Payment date',
    gestorAcreditoPay: 'Manager who proved the payment',
    reclamated: 'Claim',
    cancelated: 'Cancellation',
    table: 'Table',
    tableData: 'Data base table',
    inputs: 'Fields',
    inputsExport: 'Fields to export',
    selectAll: 'Select All',
    notShowElement: 'There are no items to display',
    videosUrl: 'Videos from external url',
    about: 'About us?',
    geolocalizar: 'Geolocate parent company',
    merchantCode: 'Merchant code',
    merchantTerminal: 'Terminal',
    merchantTransactionId: 'Transaction Id',
    currency: 'Currency',
    currencies: 'Currencies',
    clientCorporate: 'Corporate Client',
    dataNotDisponse: 'Data not available',
    infoFiscal: 'Info Fiscal',
    bancaria: 'Banking',
    credito: 'Credit',
    ejecutivos: 'Executives',
    line: 'line',
    payMedio: 'Payment methods',
    phoneUSA: 'USA cell phone',
    valueUSD: 'Value (USD)',
    dateUpdate: 'Date update',
    updated: 'Updated',
    updatedBy: 'Updated by',
    changeCurrency: 'Currency exchange',
    timeUTC: 'Time Standard (UTC)',
    timeUTCPublic: 'Publication Time T/C',
    timeUTCCheck: 'Checkup Time T/C',
    timeUTCActive: 'Activation Time T/C',
    afiliateCorporate: 'Affiliate or Corporate',
    offertsWith: 'offer with {cant} suppliers',
    nights: 'nights',
    selectCurrency: 'Select currency',
    typeAuto: 'Make sure you have an active API for automatic currency conversion.',
    season: 'Season',
    dateFrom: 'Date from',
    dateTo: 'Date until',
    destinoUOfic: 'Destination or rental office',
    operadors: 'Operadors',
    suplementsCost: 'Supplements and associated costs',
    docRequired: 'Required documentation',
    notes: 'Grades',
    roomsPlan: 'Rooms, meal plan and ages',
    adolescentes: 'Teenagers',
    estanciaDbl: 'Stay in double occupancy',
    suplRedTarif: 'Supplements, Reductions and Accommodation Rates',
    polityChild: 'Children policy',
    suplementsAlimenticios: 'Supplements and Rates for meal plan',
    suplementsOtros: 'Other supplements',
    cancelaciones: 'Not Show',
    denegarAfil: 'Deny membership request',
    denegarMotive: 'Reason',
    denegarMotiveInfo: 'Reason for denial',
    exchangeRate: 'Exchange rate',
    neto: 'Net',
    codeClient: 'Client code',
    addTarifa: 'Add rate',
    deleteTarifa: 'Destroy rate',
  },
  btn: {
    asociate: 'Associate',
    desvincule: 'Unlink',
    send: 'Send',
    select: 'Select',
    apply: 'Apply',
    add: 'Add',
    save: 'Save',
    descartar: 'Cancele',
    continue: 'Continue',
    search: 'Search',
    close: 'Close',
    cancele: 'Cancel',
    insert: 'Insert',
    generate: 'Trigger',
    regenerate: 'Regenerate',
    create: 'Create',
    copy: 'Copy',
    update: 'Update',
    updatePermisos: 'Update Permission',
    edit: 'Editr',
    delete: 'Delete',
    clone: 'Clone',
    programation: 'Schedule',
    see: 'View',
    back: 'Back',
    import: 'Import',
    export: 'Export',
    activar: 'Activate',
    desactivar: 'Deactivate',
    remplace: 'Remplace',
    all: 'All',
    acceptChange: 'Accept Changes',
    canceleChange: 'Reject Changes',
    uploadLote: 'Batch upload',
    importFiles: 'Import files',
    restaurarLote: 'Data restaure',
    restaurar: 'Restaure',
    canceleUpload: 'Cancele upload',
    reserveNow: 'Reserve now',
    reserve: 'Reserve',
    archive: 'Archive',
    next: 'Next',
    preview: 'Previous',
    complete: 'Complete',
    resize: 'Resize',
    print: 'Print',
    sendEmail: 'Send by e-mail',
    clear: 'Clear',
    addToCar: 'Add to cart',
    clearCart: 'Clear to cart',
    printCotization: 'Print quote',
    sendCotization: 'Send quote',
    editCotization: 'Edit quote',
    soliciteReserve: 'Request reservation',
    pay: 'Pay',
    cobrar: 'Cobrar',
    payReserve: 'Pay the reservation',
    cobrarReserve: 'Pay the reservation',
    share: 'Share',
    dowload: 'Download',
    gestion: 'Manage',
    emitPays: 'Issue Payments',
    gestionarCobros: 'Manage Collections',
    gestionarProveedores: 'Manage Suppliers',
    sincronizar: 'Sync up',
    yes: 'Yes',
    no: 'No',
  },
  msg: {
    dataSync: 'Data syncing',
    dataRequiere: 'You must enter your data.',
    dataSpecific: 'You must specify a date range.',
    infoSuccess: 'Updated information.',
    sendEmails: 'Your message has been sent.',
    fileUpload: 'File in process.',
    invalidCredentials: 'Invalid credentials.',
    userInactive: 'Inactive user',
    datasRequiere: 'You must fill in the required information.',
    requiereAprovation: 'Your request will be answered shortly and you will be notified.',
    noAutorice: 'You are not authorized to perform this operation.',
    dupliqueFrecuencia: 'Check your data, is duplicating rates to the contract.',
    notMatch: 'Passwords do not match.',
    deleteMessage: 'Are you sure you want to delete this message?',
    deleteItem: 'Are you sure you want to delete {item}?',
    sendNotificationUser: 'Another invitation email will be sent to the user {item}.',
    deleteSuplement: 'Are you sure you want to remove the supplement: {item}?',
    clearCart: 'Are you sure you want to clear to cart?',
    deleteImg: 'Are you sure you want to delete picture?',
    deleteVideo: 'Are you sure you want to delete video?',
    archiveChat: 'Are you sure you want to archive this chat?',
    deleteBookingItem: 'Are you sure you want to cancel the reservation {item}?',
    deleteUndo: 'The process cannot be undone',
    deleteService1: 'Se va a proceder con la Cancelación del servicio {item}.',
    deleteService2: 'Esta acción será irreversible y no se aplicará Reembolso al cliente.',
    deleteService3: 'Esta acción será irreversible y se aplicará Reembolso al cliente.',
    deleteService4: 'Tipo de reembolso',
    deleteService5: 'Cantidad a Reembolsar',
    canceleService: 'Are you sure you want to cancele the service {code}?',
    convertToReserve: 'Are you sure you want to convert the cotization {item} to reservation?',
    deleteMarkups: 'Are you sure you want to remove this Markup?',
    deleteStopSale: 'Are you sure you want to remove this Stop Sales?',
    deleteOfertEBB: 'Are you sure you want to remove this Offert?',
    deleteFrecuencia: 'Are you sure you want to delete the frecuency {item}?',
    deleteContrate: 'Are you sure you want to delete the contrate?',
    canceleContrate: 'Are you sure you want to cancele the contrate?',
    deleteFlight: 'Are you sure you want to delete the flight {item}?',
    deleteCar: 'Are you sure you want to delete the car {item}?',
    deleteHotel: 'The Hotel is going to be removed {item}. This action will be irreversible',
    deleteServicio: 'Are you sure you want to delete the service {item}?',
    errorDeleteFlight: 'It is not possible to delete the flight {item}. There are contracts related to this.',
    errorDeleteCar: 'It is not possible to delete the car {item}. There are contracts related to this.',
    errorDeleteHotel: 'It is not possible to delete the hotel {item}. There are contracts related to this.',
    errorDeleteSeasonHotel: 'It is not possible to delete this season. There are prices related to this.',
    deleteSeasonHotel: '¿ Are you sure you want to delete the season {item}?',
    selectFlight: 'Whoops!!!... you must select a flight to continue.',
    noNumberFlight: 'Whoops!!!... flight not found {numero}.',
    userExist: 'There is already a user with the email {email}',
    airlineExist: 'An airline already exists with the identifier {identificador}',
    itemExist: 'An item already exists with the name {identificador}',
    airplaneExist: 'An airplane already exists with the name {identificador}',
    roomExist: 'An room already exists with the name {identificador}',
    airplaneEdad: 'The age already exists {identificador}',
    airportExist: 'The airport already exists {identificador}',
    claseExist: 'An clase already exists with the name {identificador}',
    tarifaVueloExist: 'An rate already exists with the name {identificador}',
    dimensionExist: 'There is already a dimension of the type {identificador}',
    pesoExist: 'There is already a weight of the type {identificador}',
    transmisionExist: 'Transmission already exists {identificador}',
    typeAutoExist: 'Car type already exists {identificador}',
    typeAutoNote: 'The types or classifications of the car correspond to the automotive body.',
    marcaExist: 'The brand already exists {identificador}',
    modeloExist: 'The model already exists {identificador}',
    combustibleExist: 'Fuel already exists {identificador}',
    caracteristicaExist: 'Feature already exists {identificador}',
    categoryExist: 'Category already exists {identificador}',
    roleExist: 'Role already exists {identificador}',
    rentadoraExist: 'The renter already exists {identificador}',
    tarifaExist: 'The fee already exists {identificador}',
    tarifaMaxExist: 'If we leave the maximum number of days blank, it will be taken as unlimited, eg: +{identificador}',
    edadMaxExist: 'If we leave the maximum number of age blank, it will be taken as unlimited, eg: +{identificador}',
    sendEmail: 'Notification has been sent to {email}',
    sendEmailAgente:
      'Sales Agent created: {name}. We have sent an email to the person in charge of this account to confirm the registration and add your password.',
    existFlight:
      'Whoops!!!... there is already a registered flight with the number {numero}. Please check that you have entered the data correctly. If necessary, go to the list of available flights to edit it.',
    existCars:
      'Whoops!!!... there is already a registered car with the name {name}. Please check that you have entered the data correctly. If necessary, go to the list of available flights to edit it.',
    existProduct: 'The product {name} already exists in the System. Try changing your name.',
    existContrate:
      'Whoops!!!... a contract already exists with the identifier {identificador}. Please check that you have entered the data correctly. If necessary, go to the list of contracts to edit it.',
    problemWithPay:
      'Whoops!!!... there is a problem with your {payment} account. Please verify that you have entered the data correctly and that you have sufficient balance to execute the operation.',
    operadorExist: 'Operator already exists {identificador}',
    marcaAsociadaExist: 'The Associated Brand already exists {identificador}',

    confirmChange: 'For your changes to take effect you need to re-login. Do you wish to continue?',
    emptySeason: 'Whoops!!!... must define at least one season.',
    activeUser:
      'Are you sure you want to activate user {email}? This action will notify the user to create their profile.',
    desactiveUser:
      'Are you sure you want to disable the user {email}? This action will not allow you to enter the platform until it is activated again.',

    noElementShow: 'No items found.',
    acceptChange: 'Are you sure you want to accept the new changes?',
    rejectChange: 'Are you sure you want to reject the new changes?',

    verifyTarifa: 'Check the fare rules to find out if changes are allowed.',
    verifyReembolso: 'See fare rules for refund policy.',
    noResultFlightSearch: 'Whoops!!!... no results were found for your search criteria.',

    copyText: 'Has been copied {text}',
    noAsociedCompany: 'No company associated with this user was found.',
    successToken: 'Tokens generated successfully.',
    generateToken:
      'Are you sure you want to reset your tokens? This action will break any links from external apps that make use of your current tokens.',
    activeToken: 'This action will allow the creation of a link from external applications using their current tokens.',
    deleteToken: 'This action will break any links from external apps that make use of your current tokens.',
    apiText1:
      'The API allows the communication and integration of the B2C and third parties with the products and services of the B2B. They identify your project (the APP or the website) that makes the call to the API itself. Authentication tokens identify the user (person) using the APP or website.',
    apiText2:
      'The use of this API requires authentication and authorization (Secret Token) so it is necessary to obtain the credentials provided here automatically through the request of an Access Token.',
    apiText3:
      'It is very important that you know that whoever has your API Key will have access to your account, so you have to be extremely careful with it.',
    apiTextNoItems: 'There are no Companies connected to the API',

    noResultFlightCotizacionTitle: 'Change search parameters',
    noResultFlightCotizacionBody: 'Try searching for flights on other dates or from different airports',
    noResultCarCotizacionBody: 'Try looking for cars on other dates or from different destinations',
    noResultHotelCotizacionBody: 'Try looking for lodging on other dates or from different destinations',
    noResultTarifariosBody: 'Try searching on other dates',
    markupsNoValid: 'Sorry it is not possible to apply the established values',

    profileChangeEmailTitle: 'Your email is not confirmed. Please check your inbox.',

    passwordNotMath: 'Your current password does not match.',

    bagInclude: 'Backpack or bag included',
    bagNoInclude: 'Backpack or bag not included',
    bagDescription: 'Must fit under the front seat',

    cabinaInclude: 'Hand luggage included',
    cabinaNoInclude: 'Hand luggage not included',
    cabinaDescription: 'Must fit inside the cabin compartment',

    bodegaInclude: 'Checked baggage included',
    bodegaNoInclude: 'Checked baggage not included',
    bodegaDescription: 'It is dispatched during Check-in at the airport',
    bags: '{n} suitcase per adult | {n} suitcases per adult',
    horarioAirpor: 'All times are shown in the time of each airport.',
    flightCon: 'Tu vuelo con',
    tarifasMulti: 'Tarifas Múltiples',

    agreguePesoMax1: 'Add a maximum weight value to activate allowed overweight.',
    agreguePesoMax2: 'If a maximum weight value is not added, the baggage policy does not allow overweight.',
    equipajeSupere: 'Baggage that exceeds the maximum weight will be sent as cargo.',

    hourAirport: 'All times are given in local airport time zone.',
    durationHourMin: 'Flight time, in 24hr format (hh:mm).',
    durationTravelHourMin: 'Travel time, in 24hr format (hh:mm).',
    durationEscHourMin: 'Scale duration in 24hrs format (hh:mm).',
    timeFly: 'Travel time',

    notDeleteItem: 'It is not possible to delete this item because it has current relationships with other entities',
    deleteCategorias: 'Accepting this action will remove all categories and prices from the contract.',
    deleteModels: 'Accepting this action will remove all car models and prices from the contract.',

    desvinculeRooms: 'Accepting this action will remove all prices for this room in the contract.',
    verifiqueInfo: 'Please verify all information before entering it into the database.',
    errorHotel: 'There are inconsistencies in the hotel {item}. Review the model near "{element}".',
    errorInput:
      'There are inconsistencies in the file "{file}" in the sheet "{sheet}. The value "{item}" was expected in the column "{colum}" and comes "{element}".',
    canceleUpload: 'Are you sure you want to cancel the batch upload of {item}?',
    processUpload: 'Are you sure you want to process this information about {item}?',
    processUpload1:
      'Batch Upload involves the replacement of existing data. You will be able to return to the previous state and restore to the initial values in case of unwanted errors in the charging process.',
    processUpload2:
      'Also note that affected products will be kept Unpublished and will not show up in availability until their status is changed.',
    restoreUpload1: 'The Batch Upload will be restored to its initial values and the last change will be ignored.',
    addDelete: 'Are you sure you want to delete {item}?',
    addChange: 'Are you sure you want to add {item}?',
    addSave: 'Are you sure you want to save {item}?',
    addRemplace: 'Are you sure you want to replace {item} by {remplace}?',
    addAsociate: 'Are you sure you want to associate {item} this value: {asociate}?',
    loteContrateHotel:
      'Select the contract provider to display the data model for the .csv file that is allowed to be uploaded. For the massive upload of hotel contract information, we verify that the selected .csv file complies with the data structure previously defined for each provider. In case of inconsistencies in the data model of the selected .csv file, the massive upload of the hotel accommodation contract information for the selected provider will be aborted.',
    provedorNotConditions: 'The selected provider does not have generals conditions assigned',
    notDeleteItemRelations:
      'Whoops!!!... It is not possible to delete this item because it has current relationships with {entity}.',
    verifyEstanciaMin: 'Verify that the minimum stays are greater than 1',
    edadDriver1:
      'This car is only available for drivers of age: +{edad} years. Please, verify the age entered or select another type of car that does not require this requirement.',
    edadDriver2: 'This car is only available for drivers of age: {edad1} - {edad2} years...',
    notDeleteItemRelationsContrate:
      'Whoops!!!... It is not possible to delete this item because it is related to car contracts {contrate}.',
    notDeleteItemRelationsProduct:
      'Whoops!!!... It is not possible to delete this item because it is related to car product {product}.',
    notDeleteItemRelationsMarcaAsociada:
      'Whoops!!!... It is not possible to delete this item because it is related to {item}.',
    notDeleteItemRelationsAgents:
      'Whoops!!!... It is not possible to delete this item because it is related to {item}.',
    noRelease:
      'It is not possible to show availability with less than {days} days (Release) for the start of the service.',
    payDayGrace: 'Charge +{days} rental day',
    sendNewletter: 'Sending message to recipients',
    sendNotification: 'Customer has been notified',
    sendCotization: 'The quote has been sent to your client.',
    msgImgsNotSize: 'The following images do not meet the minimum dimensions ({dimensions})',
    reviewAmounts: 'Please revise the established amounts.',
    cxPSameProv:
      'It is not possible to issue this payment, verify that all the selected Accounts Payable belong to the same supplier.',
    msgCaduquedCotization: 'The quote with code {code} has expired.',
    msgSendEmail: 'The email has been sent to {email}.',
    msgCaducaLink1:
      'The document you are trying to access may have expired. We suggest you scan the QR code or check the number again.',
    msgCaducaLink2: 'If the problem repeats please contact Support({email}, {phone}).',
    msgChangeRooms:
      'The rooms of the Hotel {hotel} that you are trying to modify are connected to the Channel Manager Hotetec so you must be sure of the action you are performing.',
    noResultFiltrado: 'There are no results for the search carried out. Please change the filters and try again.',
    payConfirm: 'Your payment has been satisfactory. Enjoy the service.',
    noTransactions: 'No transactions.',
    errorRefund: 'Connection with the payment gateway was not possible. Try again later please.',
    errorRefundMonto: 'You are trying to refund an illegal value.',
    noReservas: 'Sin reservas.',
    noCotizations: 'Sin cotizaciones.',
    changeFilters: 'Por favor cambie los filtros.',
    hotelsWithTarifa: 'Ya los siguientes hoteles tienen esa tarifa: {hotels}',
    saveChangesTarifa: 'Before changing the rate you must save the changes made, otherwise these changes will be lost.',
    saveChangesCambios: 'Antes de abandonar la página debe guardar los cambios realizados, de lo contrario estos cambios se perderán.',
  },
  password: {
    title: 'Password should contain',
    security: 'Choose a safer password. Try a combination of letters, numbers, and symbols.',
    min_rules: 'Use a minimum of eight characters, with a combination of letters, numbers and symbols.',
    not_math: 'Passwords do not match. Try again.',
    has_minimum_lenth: 'Atleast 8 chacters',
    has_lowercase: 'One lowercase letter',
    has_uppercase: 'One uppercase letter',
    has_number: 'One number',
    has_special: 'One special character',
  },
  menu: {
    dashboard: 'Dashboard',
    subheader: 'TOOLS',
    acceso: 'Access',
    booking: 'Booking',
    comitions: 'Currency exchange',
    finanzas: 'Finance',
    ctas: 'Accounts',
    ctasXC: 'Accounts by receivable',
    ctaXC: 'Account by receivable',
    ctasXP: 'Accounts by pay',
    ctaXP: 'Account by pay',
    pays: 'Payments',
    paysRec: 'Payments received',
    paysEmit: 'Issued payments',
    contability: 'Accounting',
    cuentasXCobrar: 'Bills x receivable',
    cuentasXPagar: 'Bills x pay',
    cuentasAfiliados: 'Account status Affiliates',
    permisos: 'Accession',
    cotizador: 'Quoter',
    reservas: 'Bookings',
    tarifarios: 'Rates',
    tarifariosNetos: 'Net Rates',
    cotizations: 'Cotizations',
    pvp: 'PVP',
    nomenclador: 'Nomenclators',
    suplementos: 'Suplements',
    suplementosReduc: 'Suplements / Reductions',
    reducciones: 'Reductions',
    typeAfiliado: 'Affiliate Type',
    categoryCondition: 'Condition Categories',
    conditionGenerals: 'Condition General',
    roles: 'Rols',
    perfilUser: 'Profiles',
    users: 'Users',
    usersSon: 'Child Users',
    usersPropio: 'Own',
    usersAdmin: 'Administrator',
    userRegister: 'Affiliates',
    companies: 'Companies',
    agentes: 'Agents',
    operations: 'Operations',
    logs: 'Logs',
    markups: 'Markups',
    stopSale: 'Sale Stop',
    oferts: 'Oferts',
    export: 'Export',
    administration: 'Administration',
    gestion: 'Management',
    configuration: 'Setting',
    dataGeneral: 'Owner',
    asistencia: 'Assistance',
    jsonAirport: 'JSON Airport',
    products: 'Products',
    flights: 'Flights',
    cars: 'Cars',
    hotels: 'Hotels',
    contratos: 'Contracts',
    class: 'Classes',
    tarifas: 'Rates',
    tarifasXKm: 'Rates per km',
    aeronave: 'Aircraft',
    airport: 'Airports',
    airports: 'airports',
    aerolinea: 'Airlines',
    dimensiones: 'Luggage dimensions',
    dimensione: 'Dimensions',
    pesos: 'Luggage weights',
    peso: 'Weights',
    cardFidelidad: 'Loyalty cards',
    card: 'Cards',
    codeOFAC: 'OFAC codes',
    politicaTarifa: 'Tariffs Policys',
    code: 'Codes',
    trasnmision: 'Transmission',
    transmisiones: 'Transmissions',
    typeCar: 'Body Type',
    types: 'Types',
    marca: 'Brand',
    marcas: 'Brands',
    modelo: 'Model',
    modelos: 'Models',
    combustible: 'Fuel',
    combustibles: 'Fuels',
    caracteristicas: 'Features',
    category: 'Categories',
    rentadora: 'Rental company',
    rentadoras: 'Rentals',
    oficinaRenta: 'Rental point',
    oficinas: 'Point',
    ptoRecogida: 'Pick up point',
    ptos: 'Points',
    edades: 'Ages',
    typeDestination: 'Type of destinations',
    operador: 'Operator',
    marcaAsociada: 'Associated Brand',
    intereses: 'Interests',
    servicios: 'Services and Facilities',
    habitaciones: 'Bedrooms',
    categoriesTarifarios: 'Rates Categories',
    regimenAlimenticio: 'Nutritional regimen',
    edadMin: 'Minimum age',
    tagsImg: 'Image Tags',
    tutorials: 'Tutorials',
    transfers: 'Transfers',
    excursions: 'Excursions',
    packages: 'Packages',
    cruise: 'Cruise',
  },
  rules: {
    min6: 'At least 6 characters',
    upload1MB: 'Avatar size should be less than 1 MB!',
  },
  footer: {
    allRights: 'All rights Reserved',
    powered: 'Powered by',
  },
  user: {
    profile: 'Profile',
    logout: 'Logout',
    data: 'General Data',
    security: 'Security',
  },
  permiso: {
    zonas: 'Zones',
    moderations: 'Moderations',
    chatbot: 'ChatBot',
    configuration: 'Setting',
    messagesInit: 'Automatic messages',
    talking: 'Conversations',
    profils: 'Profiles',
    ver: 'view',
    edit: 'Edit',
    create: 'Create',
    delete: 'Delete',
    views: 'Views',
    tableros: 'dashboards',
    permiso: 'Permissions',
    roles: 'Rols',
    perfilUser: 'Affiliate profiles',
    usuarios: 'Users',
    typeAfiliado: 'Affiliate Type',
    agente: 'Agents',
    userRegister: 'Registered users',
    markups: 'Markups',
    configData: 'General configuration',
    flights: 'Flights',
    contrate_flights: 'Contracts Flights',
    class: 'Classes',
    aeronave: 'Aircraft',
    aerolinea: 'Airlines',
    tarifa_flights: 'Rates',
    dimensiones: 'Luggage dimensions',
    pesos: 'Luggage weights',
    logs: 'Logs',
    cars: 'Cars',
    trasnmision: 'Transmission',
    typeCar: 'Car type',
    marca: 'Brand',
    modelo: 'Model',
    combustible: 'Fuel',
    caracteristicas: 'Features',
    category: 'Categories',
    rentadora: 'Rental company',
    oficinaRenta: 'Rental point',
    ptoRecogida: 'Pick up point',
    edadConductor: "Driver's age",
    tarifas: 'Car Day Range',
  },
  Dashboards: 'Dashboards',
  CRM: 'CRM',
  Analytics: 'Analytics',
  eCommerce: 'eCommerce',
  Calendar: 'Calendar',
  Chat: 'Chat',
  Email: 'Email',
  Invoice: 'Invoice',
  List: 'List',
  Preview: 'Preview',
  Edit: 'Edit',
  Add: 'Add',
  User: 'User',
  'User List': 'User List',
  'User View': 'User View',
  'APPS AND PAGES': 'APPS AND PAGES',
  Pages: 'Pages',
  Authentication: 'Authentication',
  'Login V1': 'Login V1',
  'Login V2': 'Login V2',
  'Register V1': 'Register V1',
  'Register V2': 'Register V2',
  'Forgot Password V1': 'Forgot Password V1',
  'Forgot Password V2': 'Forgot Password V2',
  'Reset Password V1': 'Reset Password V1',
  'Reset Password V2': 'Reset Password V2',
  Miscellaneous: 'Miscellaneous',
  'Under Maintenance': 'Under Maintenance',
  Error: 'Error',
  'Coming Soon': 'Coming Soon',
  'Not Authorized': 'Not Authorized',
  'Knowledge Base': 'Knowledge Base',
  'Account Settings': 'Account Settings',
  Pricing: 'Pricing',
  FAQ: 'FAQ',
  'USER INTERFACE': 'USER INTERFACE',
  Typography: 'Typography',
  Icons: 'Icons',
  Gamification: 'Gamification',
  Cards: 'Cards',
  Basic: 'Basic',
  Statistics: 'Statistics',
  Advance: 'Advance',
  Actions: 'Actions',
  Chart: 'Chart',
  Components: 'Components',
  Alert: 'Alert',
  Avatar: 'Avatar',
  Badge: 'Badge',
  Button: 'Button',
  Menu: 'Menu',
  'Expansion Panel': 'Expansion Panel',
  Dialog: 'Dialog',
  Pagination: 'Pagination',
  Tabs: 'Tabs',
  Tooltip: 'Tooltip',
  Chip: 'Chip',
  Snackbar: 'Snackbar',
  Stepper: 'Stepper',
  Timeline: 'Timeline',
  Treeview: 'Treeview',
  'FORMS & TABLES': 'FORMS & TABLES',
  'Forms Elements': 'Forms Elements',
  Autocomplete: 'Autocomplete',
  Checkbox: 'Checkbox',
  Combobox: 'Combobox',
  'File Input': 'File Input',
  Radio: 'Radio',
  'Range Slider': 'Range Slider',
  Select: 'Select',
  Slider: 'Slider',
  Switch: 'Switch',
  Textarea: 'Textarea',
  Textfield: 'Textfield',
  'Date Picker': 'Date Picker',
  'Month Picker': 'Month Picker',
  'Time Picker': 'Time Picker',
  Rating: 'Rating',
  'Form Layout': 'Form Layout',
  'Form validation': 'Form validation',
  Tables: 'Tables',
  'Simple Table': 'Simple Table',
  Datatable: 'Datatable',
  'CHARTS AND MAPS': 'CHARTS AND MAPS',
  Charts: 'Charts',
  'Apex Chart': 'Apex Chart',
  Chartjs: 'Chartjs',
  'Leaflet Maps': 'Leaflet Maps',
  Others: 'Others',
  'Menu Levels': 'Menu Levels',
  'Menu Level 2.1': 'Menu Level 2.1',
  'Menu Level 2.2': 'Menu Level 2.2',
  'Menu Level 3.1': 'Menu Level 3.1',
  'Menu Level 3.2': 'Menu Level 3.2',
  'Disabled Menu': 'Disabled Menu',
  'Raise Support': 'Raise Support',
  Documentation: 'Documentation',
  'Charts & Maps': 'Charts & Maps',
  Apps: 'Apps',
  'User Interface': 'User Interface',
  'Forms & Tables': 'Forms & Tables',
}
